@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Raleway:400,600,800|Courier+Prime&display=swap');

@import "image-gallery.scss";
@import "carousel.css";

$primaryHeaderFont: Montserrat;
$primaryMainFont: Raleway;
$primaryAccentColor: #2382a5;
$secondAccentColor: #617;
$code: #B01055;

$primaryBackgroundColor: #d1d1d1;
$lightgrey: #eee;
$medgrey: #d1d1d1;
$darkgrey: #b2b2b2;
$darkergrey: #262626;

* {
    margin: 0;
    padding: 0;
}
html {
    background-color: whitesmoke;
}
.App {
    margin: 0 0 30px;
    padding: 0 0 30px;
    top: 0;
    height: 100%;
}
.homeContainer {
    display: grid;
    position: relative;
    padding-top: 65px;
    margin: 0 50px 65px 50px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 210px;
    row-gap: 25px;
    column-gap: 25px;
    text-align: center;
    background-position: center;
    background-size: center;
    justify-content: center;
    flex-direction: row;
    z-index: 1;

    @media (min-width: 550px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 750px) {
        grid-template-columns: 1fr 1fr 1fr 1fr ;
        padding-top: 85px;
        margin: 0 50px 85px 50px;
        row-gap: 30px;
        column-gap: 30px;
    }
    @media (min-width: 900px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto 233px;
    }
    @media (min-width: 1100px) {
        grid-template-rows: auto 267px;
        row-gap: 40px;
        column-gap: 40px;
    }
    @media (min-width: 1400px) {
        padding-top: 100px;
        margin: 0 50px 100px 50px;
        row-gap: 50px;
        column-gap: 50px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}
.homeSpacer {
    height: 25px;
    border: 0;
    box-shadow: inset 0 25px 25px -25px $medgrey;
    margin-top: -35px;
    margin-bottom: -55px;
    z-index: 100 !important;
    // background-color: red;
    width: 100%;
}
.homeSpacerUX {
    height: 25px;
    border: 0;
    box-shadow: inset 0 25px 25px -25px $medgrey;
    margin-top: -35px;
    margin-bottom: 20px;
    z-index: 100 !important;
    // background-color: red;
    width: 100%;
}
.footerLink {
    font-family: $primaryHeaderFont;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    text-decoration: none;
    color: $lightgrey;
    letter-spacing: .15px;
    line-height: 1.8;

    &:hover {
        color: $primaryAccentColor;
        font-weight: 600;
    }
    @media (min-width: 400px) {
        font-size: 12px;
    }
    @media (min-width: 500px) {
        font-size: 13px;
        line-height: 1.5;
    }
    @media (min-width: 750px) {
        font-size: 15px;
    }
}
.footCont {
    background-color: $darkergrey;
    display: flex;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    height: 40px;
    padding: 10px;
    line-height: 40px;
    justify-content: space-evenly;
    text-align: center;
    z-index: 5;
    box-sizing: border-box;
}
.navbarLink {
    display: block;
    position: relative;
    font-family: $primaryHeaderFont;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    font-size: 11.5px;
    color: $lightgrey;
    letter-spacing: .15px;
    z-index: 5;
    line-height: 1.8;
    margin: 0px 5px 3px;
    cursor: pointer;

    &:hover {
        color: $primaryAccentColor;
        font-weight: 600;
        letter-spacing: -.01px;
    }
    @media (min-width: 400px) {
        font-size: 12px;
    }
    @media (min-width: 500px) {
        font-size: 13px;
        line-height: 1.5;
    }
    @media (min-width: 750px) {
        font-size: 15px;
        margin: 0px 5px 1px;
    }
}
.mobileHide {
    display: none;
    
    @media (min-width: 500px) {
        display: inline-block;    
    }
}
.navbarLinkActive {
    display: block;
    position: relative;
    color: whitesmoke;
    font-weight: 600;
    padding: 0 2px 0;
    margin: 0px 5px 1q;
    font-size: 11.5px;
    letter-spacing: -.01px;
    z-index: 5;
    line-height: 1.8;
    cursor: pointer;

    @media (min-width: 400px) {
        font-size: 12px;
        border-bottom: 4px solid $primaryAccentColor;
        margin-bottom: -2.5q;   
    }
    @media (min-width: 500px) {
        border-bottom: 5px solid $primaryAccentColor;
        margin-bottom: -5.5q;   
        font-size: 13px;
        line-height: 1.5;
    }
    @media (min-width: 750px) {
        font-size: 15px;
        letter-spacing: -.1px;
    }
} 

// .navbarLinkActive:after {
//     display: none;

//     @media (min-width: 500px) {
//         display: inline-block;
//         content: '';
//         position: absolute;
//         border-top: 5px solid whitesmoke;
//         border-left: solid 12px transparent;
//         border-right: solid 12px transparent;
//         bottom: -7px;
//         margin: 0 auto;
//         bottom: -8px;
//         left: 38%;
//         right: 45%;
//     }
//     @media (min-width: 750px) {
//         left: 43%;
//         right: 45%;
//     }
// } 
.navbarCont {
    background-color: $darkergrey;
    display: flex;
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 40px;
    padding: 10px;
    line-height: 40px;
    justify-content: space-evenly;
    text-align: center;
    z-index: 6;
    box-sizing: border-box;
    // border-bottom: 2px $darkergrey solid;
}
.designthumb {
    width: 150px;
    height: 200px;
    background-color: $darkgrey;
    background-position: center;
    background-size: cover;
    display: flex;
    position: relative;
    text-align: center;
    justify-content: center;  
    border-radius: 2px;
    z-index: 0;
    margin: 1px solid black !important;
    box-shadow: $primaryBackgroundColor 5px 5px 10px 1px, 0px 0px 5px $primaryBackgroundColor;
    -webkit-box-shadow: $primaryBackgroundColor 5px 5px 10px 1px, 0px 0px 5px $primaryBackgroundColor;
    -moz-box-shadow: $primaryBackgroundColor 5px 5px 10px 1px, 0px 0px 5px $primaryBackgroundColor;

    @media (min-width: 550px) {
        width: 150px;
        height: 200px;
    }
    @media (min-width: 750px) {
        width: 150px;
        height: 200px;
    }
    @media (min-width: 900px) {
        width: 175px;
        height: 233px;
    }
    @media (min-width: 1100px) {
        width: 200px;
        height: 267px;
    }
    // @media (min-width: 1400px) {
    //     width: 225px;
    //     height: 300px;
    // }
}
.projectParentRelative {
    position: relative;
    width: 150px;
    height: 200px;
    z-index: 5;
    text-align: center;
    margin: auto;

    @media (min-width: 550px) {
        width: 150px;
        height: 200px;
    }
    // @media (min-width: 750px) {
    //     width: 150px;
    // }
    @media (min-width: 900px) {
        width: 175px;
        height: 233px;
    }
    @media (min-width: 1100px) {
        width: 200px;
        height: 267px;
    }
    // @media (min-width: 1400px) {
    //     width: 225px;
    //     height: 300px;
    // }
}
.projectParentAbsolute {
    position: absolute;
    width: 150px;
    z-index: 5;
    top: 75px;

    @media (min-width: 550px) {
        width: 150px;
    }
    @media (min-width: 750px) {
        width: 150px;
        top: 85px;
    }
    @media (min-width: 900px) {
        width: 175px;
        top: 95px;
    }
    @media (min-width: 1100px) {
        width: 200px;
        top: 110px;
    }
    // @media (min-width: 1400px) {
    //     width: 225px;
    //     height: 300px;
    // }
    &:hover {
        opacity: 0;
        transition: opacity .20s ease-in-out;
        -moz-transition: opacity .20s ease-in-out;
        -webkit-transition: opacity .20s ease-in-out;
    }
}
.projectBar {
    background: rgba(255, 255, 255, 0.897);
    display: block;
    height: auto;
    width: 125px;
    z-index: 3;
    margin-left: 12.5px;
    align-content: center;
    border: 2px white solid;
    box-sizing: border-box;
    box-shadow: $darkgrey 1px 1px 5px;
    padding: 5px 5px 6px;

    @media (min-width: 550px) {
        width: 125px;
    }
    @media (min-width: 750px) {
        width: 125px;
    }
    @media (min-width: 900px) {
        width: 150px;
        // top: 100px;
    }
    @media (min-width: 1100px) {
        width: 175px;    
        // top: 140px;
    }
    @media (min-width: 1400px) {
        // width: 200px;    
        // top: 150px;
    }
}
.projectName {
    font-family: $primaryHeaderFont;
    display: block;
    font-size: 12px;
    color: black;
    text-decoration: none;
    z-index: 4;
    width: 100%;
    line-height: 1.2;
    letter-spacing: .3px;
}
.projectType {
    font-family: $primaryMainFont;
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: $darkergrey;
    text-decoration: none;
    width: 100%;
    letter-spacing: .4px;
    margin-top: 5px;
}
.iframe {
    position: relative;
    top: 0;
    left: 0;
    border: 1px solid $darkgrey;
    width: 300px;
    height: 198px;
    margin: auto;
    margin-top: 25px;

    @media (min-width: 550px) {
        width: 400px;
        height: 264px;
    }
    @media (min-width: 750px) {
        width: 600px;
        height: 395px;
    }
    @media (min-width: 900px) {
        width: 683px;
        height: 450px;
    }
    @media (min-width: 1100px) {
        width: 683px;
        height: 450px;
    }
}
.iframeDisclaimer {
    margin: 10px 75px 100px;
    font-family: $primaryMainFont;
    font-size: 12px;
}
.profile {
    text-align: center;
    display: relative;
}
.profile-container {
    margin: 0px auto 70px;
}
.profile2 {
    // text-align: center;
    margin: 40px auto 10px;
    display: relative;
    @media (min-width: 700px) {
        font-size: 20px;
        margin: 50px auto 10px;
    }
    @media (min-width: 850px) {
        font-size: 22px;
        margin: 60px auto 10px;
    }
    @media (min-width: 1000px) {
        font-size: 26px;
        margin: 70px auto 10px;
    }
}
.categorySpacer {
    font-family: $primaryMainFont;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 7px;
    margin: 25px 40px ;
    width: auto;
    color: black;
    display: flex;
    flex-direction: row;

    @media (min-width: 500px) {
        font-size: 18px;
        margin: 25px 35px ; 
    }
    @media (min-width: 700px) {
        font-size: 22px;
    }
    @media (min-width: 1000px) {
        font-size: 26px;
        margin: 25px 75px 25px; 
    }
}
.categorySpacer::before {
    content: "";
    flex: 1 1;
    border-bottom: 1.5px solid #999;
    margin: auto 10px auto 0;
}
.categorySpacer::after {
    content: "";
    flex: 1 1;
    border-bottom: 1.5px solid #999;
    margin: auto 0 auto 10px;
}
.welcome {
    padding: 25px 35px 15px;
    background: rgba(35,130,165,1);
    background: url('./images/LCC-Superior-180.jpg'), linear-gradient(0deg, rgba(245,245,245,1) 20%, rgba(35,130,165,1) 80%);
    // background: whitesmoke url('./images/LCC-Superior-180.jpg');
    height: 200px;
    background-attachment: fixed;
    background-position: center 5%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    box-sizing: border-box;

    @media (min-width: 500px) {
        height: 250px;
        padding: 35px 35px 15px;
    }
    @media (min-width: 700px) {
        height: 300px;
        padding: 60px 35px 15px;
        background-position: center 0%;
    }
    @media (min-width: 1000px) {
        height: 350px;
        padding: 65px 35px 15px;
    }
    @media (min-width: 1200px) {
        background-position: center 10%;
        height: 400px;
    }
    @media (min-width: 1400px) {
        height: 450px;
    }
}
.welcome:before {
    content: "";
    position: absolute;
    height: 200px;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 254, 254, 0.404);

    @media (min-width: 500px) {
        height: 250px;
    }
    @media (min-width: 700px) {
        height: 300px;
    }
    @media (min-width: 1000px) {
        height: 350px;
    }
    @media (min-width: 1200px) {
        height: 400px;
    }
    @media (min-width: 1400px) {
        height: 450px;
    }
  }
  .welcomeName {
    position: relative;
    font-family: $primaryMainFont;
    margin: 100px auto 2px;
    padding-right: 100px;
    font-size: 5.5vw;
    line-height: 1.4;
    letter-spacing: .5px;
    text-shadow: 0px 0px 10px  #fff;
    font-weight: 600;
    z-index: 2;
    -webkit-animation: fade-in-top 2s 1s ease both;
        animation: fade-in-top 2s 1s ease both;

    @media (min-width: 500px) {
    }
    @media (min-width: 700px) {
        font-size: 5vw;
        text-shadow: 0px 0px 20px  #fff;
    }
    @media (min-width: 1000px) {
        // font-size: 45px;
        margin: 125px auto 2px;
        padding-right: 125px;
    }
    @media (min-width: 1200px) {
        // font-size: 52px;
        margin: 150px auto 2px;
    }
    // @media (min-width: 1500px) {
    // }
}
.welcomeDescription {
    position: relative;
    font-family: $primaryMainFont;
    color: #444444;
    font-size: 4.5vw;
    padding-left: 75px;
    line-height: 1.4;
    letter-spacing: .5px;
    text-shadow: 0px 0px 10px  #fff;
    font-weight: 600;
    z-index: 2;
    -webkit-animation: fade-in-top 2s 2s ease both;
    animation: fade-in-top 2s 2s ease both;

    @media (min-width: 500px) {
    //   font-size: 5vw;
    padding-left: 100px;

    }
    @media (min-width: 700px) {
        font-size: 4vw;
        text-shadow: 0px 0px 20px  #fff;
    }
    @media (min-width: 1000px) {
        // font-size: 33px;
        padding-left: 125px;
    }
    @media (min-width: 1200px) {
        // font-size: 36px;
    }
    @media (min-width: 1500px) {
        // font-size: 42px;
    }
}
@-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px), scale(0.75);
              transform: translateY(-50px), scale(0.75);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0), scale(1);
              transform: translateY(0), scale(1);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px), scale(0.75);
              transform: translateY(-50px), scale(0.75);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0), scale(1);
              transform: translateY(0), scale(1);
      opacity: 1;
    }
  }
.careerCode {
    font-family: 'Courier New', Courier, monospace;
    margin: 40px auto 15px;
    padding: 0 25px;
    font-size: 13px;
    width: 75%;
    box-sizing: border-box;
    color: $secondAccentColor;
    font-weight: 600;
    letter-spacing: .4px;
    display: inline-block;
    -webkit-animation: text-shadow-pop-bottom 3s 3s ease forwards ;
        animation: text-shadow-pop-bottom 3s 3s ease forwards ;

    &:hover {
        // -webkit-animation: text-shadow-pop-bottom 3s ease forwards infinite;
        // animation: text-shadow-pop-bottom 3s ease forwards infinite;
        color: $primaryAccentColor;
    }
    @media (min-width: 500px) {
        font-size: 15px;
        margin: 40px 25px 15px;
        padding: 0;
    }
    @media (min-width: 750px) {
        font-size: 18px;
        margin: 50px 15px 25px;

    }
    @media (min-width: 850px) {
        font-size: 22px;
        margin: 60px 25px 35px;
    }
    @media (min-width: 1000px) {
        font-size: 26px;
        margin: 70px 35px 45px;
    }
}
@-webkit-keyframes text-shadow-pop-bottom {
    0% {
      text-shadow: 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey;
      transform: scale(1, 1);
    }
    50% {
      text-shadow: 0 1px 3px 2px $medgrey, 0 2px 2px $medgrey, 0 3px 2px $medgrey, 0 4px 2px $medgrey, 0 5px 5px $medgrey, 0 6px 5px $medgrey, 0 7px 5px $medgrey;
      transform: scale(1.26, 1.26);
      color: $primaryAccentColor;
    }
    100% {
        text-shadow: 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey;
        transform: scale(1.1, 1.1);
      }
  }
  @keyframes text-shadow-pop-bottom {
    0% {
      text-shadow: 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey;
      transform: scale(1, 1);
    }
    50% {
      text-shadow: 0 1px 2px $medgrey, 0 2px 2px $medgrey, 0 3px 2px $medgrey, 0 4px 2px $medgrey, 0 5px 5px $medgrey, 0 6px 5px $medgrey, 0 7px 5px $medgrey;
      transform: scale(1.26, 1.26);
      color: $primaryAccentColor;
    //   -webkit-transform: translateY(-8px);
    //           transform: translateY(-8px);
    }
    100% {
        text-shadow: 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey, 0 0 $medgrey;
        transform: scale(1.1, 1.1);
      //   -webkit-transform: translateY(0);
      //           transform: translateY(0);
      }
  }
.profileHistory {
    font-family: $primaryMainFont;
    margin: 0px 40px 15px;
    font-size: 15px;
    text-align: left;
    line-height: 1.5;
    font-weight: 500;

    @media (min-width: 400px) {
        margin: 0px 35px 15px; 
    }
    @media (min-width: 700px) {
        font-size: 17px;
        line-height: 1.7;
    }
    @media (min-width: 1000px) {
        font-size: 18px;
        margin: 0px 75px 25px;
        line-height: 1.9;
    }
}
.highlight {
    color: $primaryAccentColor;
    display: inline;
    font-weight: 600;
}
.profileHeader {
    position: relative;
    display: inline-block;
    padding: 0 2px;
    font-family: $primaryMainFont;
    color: $primaryAccentColor;
    margin: 15px 35px 5px;
    font-size: 17px;
    text-align: left;
    line-height: 1.4;
    font-weight: 600;
    border-bottom: 1.5px solid $darkgrey;

    @media (min-width: 700px) {
        font-size: 17px;
    }
    @media (min-width: 1000px) {
        font-size: 18px;
        margin: 25px 75px 7px;
    }
}
.profileLineSD {
    width: 303px;
    margin: 0px 35px;
    border-top: 1px solid $medgrey;

    @media (min-width: 400px) {
        width: 341px;
    }
    @media (min-width: 458px) {
        width: 382px;
    }
    @media (min-width: 500px) {
        width: 378px;
    }
    @media (min-width: 700px) {
        width: 585px;
    }
    @media (min-width: 1000px) {
        width: 650px;
        margin: 0px 75px;
    }
}
.profileLineD {
    width: 312px;
    margin: 0px 35px;
    border-top: 1px solid $medgrey;

    @media (min-width: 500px) {
        width: 385px;
    }
    @media (min-width: 700px) {
        width: 445px;
    }
    @media (min-width: 1000px) {
        width: 495px;
        margin: 0px 75px;
    }
}
.profileLineC {
    width: 224px;
    margin: 0px 35px;
    border-top: 1px solid $medgrey;
    @media (min-width: 700px) {
        width: 255px;
    }
    @media (min-width: 1000px) {
        width: 282px;
        margin: 0px 75px;
    }
}
.profileSmHead {
    font-family: $primaryMainFont;
    font-weight: 600;
    margin: 3px 25px 0px 35px;
    font-size: 15px;
    text-align: left;
    line-height: 1.4;

    @media (min-width: 400px) {
    }
    @media (min-width: 700px) {
        font-size: 17px;
    }
    @media (min-width: 1000px) {
        font-size: 18px;
        margin: 3px 0px 0px 75px;
    }
}
.profileSmHeadPhone {
    font-family: $primaryMainFont;
    font-weight: 600;
    margin: 3px 0px 30px 35px;
    font-size: 15px;
    text-align: left;
    line-height: 1.4;

    @media (min-width: 400px) {
    }
    @media (min-width: 700px) {
        font-size: 17px;
        margin: 3px 0px 40px 35px;
    }
    @media (min-width: 1000px) {
        font-size: 18px;
        margin: 3px 0px 40px 75px;
    }
}
.profileDetail {
    font-family: $primaryMainFont;
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    line-height: 1.4;
    display: inline;

    @media (min-width: 700px) {
        font-size: 17px;
        margin: 20px 0px 0px;
    }
    @media (min-width: 1000px) {
        font-size: 18px;
        margin: 20px 0px 0px;
    }
}
.profileDetailEmail {
    font-family: $primaryMainFont;
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    line-height: 1.4;
    display: inline;
    text-decoration: none;
    color: black;

    &:hover {
        animation: email-hover 4s ease infinite;
    }
    @media (min-width: 700px) {
        font-size: 17px;
        margin: 20px 0px 0px;
    }
    @media (min-width: 1000px) {
        font-size: 18px;
        margin: 20px 0px 0px;
    }
}
@keyframes email-hover {
    
    15% {
        color: $secondAccentColor;
    }
    50% {
        color: $primaryAccentColor;        
    }
    100% {
        color: $secondAccentColor;
    }
}
.resumeLink {
    font-family: $primaryMainFont;
    margin: 50px auto 0;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    display: block;
    width: 185px;
    background-color: $primaryAccentColor;
    color: white;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 5px;

    &:hover {
        background-color: $secondAccentColor;
    }

    @media (min-width: 500px) {
        font-size: 18px;
        width: 250px;
    }
    @media (min-width: 700px) {
        font-size: 22px;
    }
    @media (min-width: 1000px) {
        font-size: 24px;
    }
}