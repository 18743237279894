@import "appMainStyles.scss";

.carouselDiv {
    text-align: center;
    margin: 10px auto;
    border-bottom: 50px solid whitesmoke;
}
.imageCarousel {
    position: relative;
    background-color: none;
    margin: 20px auto 20px;
    text-align: center;
    width: 325px;
 
    @media (min-width: 400px) {
        width: 350px;
    }
    @media (min-width: 550px) {
        width: 500px;
    }
    @media (min-width: 750px) {
        width: 700px;
        max-height: 500px;
    }
    @media (min-width: 900px) {
        width: 800px;
        max-height: 600px;
    }
    // @media (min-width: 1100px) {
        
    // }
}
.carouselDivWP {
    text-align: center;
    margin: 40px auto 30px;
    border-bottom: 50px solid white;
}
.imageCarouselWP {
    position: relative;
    background-color: none;
    margin: 20px auto 40px;
    text-align: center;
    width: 325px;

    @media (min-width: 400px) {
        width: 350px;
    }
    @media (min-width: 550px) {
        width: 500px;
    }
    @media (min-width: 750px) {
        width: 700px;
        max-height: 500px;
    }
    @media (min-width: 900px) {
        width: 800px;
        max-height: 600px;
    }
    // @media (min-width: 1100px) {
        
    // }
}
.waffler {
    width: 300px;
    @media (min-width: 400px) {
        width: 350px;
    }
    @media (min-width: 550px) {
        width: 500px;
    }
    @media (min-width: 750px) {
        width: 700px;
    }
    @media (min-width: 900px) {
        width: 800px;
        max-height: 600px;
    }
}
.uxContainer {
    margin: 75px 0 70px;
    display: flex;
    flex-direction: row;
}
.imageCarouselUX {
    margin: 0px auto 100px;
    text-align: center;
    width: 300px;
    max-height: 600px;
    z-index: 1;

    @media (min-width: 400px) {
        width: 350px;
    }
    @media (min-width: 550px) {
        width: 400px;
    }
    @media (min-width: 700px) {
        width: 500px;
    }
    @media (min-width: 900px) {
        width: 600px;
    }
    @media (min-width: 1100px) {
        width: 700px;
        max-height: 800px;
    }
    @media (min-width: 1400px) {
        width: 900px;
        max-height: 900px !important;
    }
}
.carousel .slide img {
    pointer-events: all;
}
.homeTitle {
    position: relative;
    display: block;
    width: 90%;
    margin: 0 auto 5px;
    padding: 5px 10px;
    text-align: center;
    font-family: $primaryHeaderFont;
    font-style: normal;
    font-weight: 500;
    background-color: #f68b67;
    color: whitesmoke;
    text-decoration: none;
    font-size: 12px;
    border-left: 10px #5c7f71 solid;
    border-right: 10px #5c7f71 solid;
    &:hover {
        color: #5c7f71;
        font-weight: 600;
    }
    @media (min-width: 500px) {
        font-size: 13px;
    }
    @media (min-width: 750px) {
        font-size: 15px;
    }
}
.homeWaffler {
    position: relative;
    display: block;
    width: auto;
    padding: 5px 0 0;
    background-color: whitesmoke;
    margin: 0 auto ;
    text-align: center;

}
.wafflerFullHeight {
    vertical-align: top;
    height: calc((80vw * 8981)/1500);
    border-left: 5px white solid;
    border-right: 5px white solid;
}
.carousel .slide {
    background-color: whitesmoke !important;
    margin: 0 auto 0px;
}
.carousel .slide img {
    // max-height: 600px;  /* change this to whatever you want */
    width: 100%;
    object-fit: cover;
}
.carousel .thumb {
    height: 80px !important;
    margin-bottom: 55px;
}
.li.slide.selected {
    background-color: none;
    position: relative;
    z-index: 3;
    width: 90%;
    height: auto !important;
}
.ul.control-dots {
    margin-top: 30px;
    margin-bottom: 0px !important;
}
.ul.slider.animated {
    transform: translate3d(0%, 0px, 0px) !important;
    -webkit-transform: translate3d(0%, 0px, 0px) !important;
}
.parentDiv {
    // display: none;
    text-align: center;
    margin: 90px 0 0;
    // @media (min-width: 500px) {
    //     display: block;
    // }
}
.webGitHubLinks {
    margin-top: 60px;

    @media (min-width: 400px) {
        font-size: 15px;
        margin: 70px 45px 0px;
    }
    @media (min-width: 550px) {
        font-size: 16px;
    }
    @media (min-width: 750px) {
        font-size: 16px;
        margin: 80px 45px 0px;
    }
}
.projectLink {
    display: flex;
    position: relative;
    text-decoration: none;
    font-family: $primaryHeaderFont;
    font-weight: 600;
    color: $primaryAccentColor;
    width: 150px;
    height: 200px;

    @media (min-width: 550px) {
        width: 150px;
        height: 200px;
    }
    @media (min-width: 750px) {
        width: 150px;
        height: 200px;
    }
    @media (min-width: 900px) {
        width: 200px;
        height: 267px;
    }
    @media (min-width: 1100px) {
        width: 225px;
        height: 300px;
    }
}
.projectLinkWebPage {
    text-decoration: none;
    font-family: $primaryHeaderFont;
    font-weight: 600;
    color: $primaryAccentColor;
    display: inline-block;
    position: relative;
    line-height: 1.4;
    font-size: 15px;
    margin: 20px 45px 0px;

    &:hover {
        color: $secondAccentColor;
    }

    @media (min-width: 400px) {
        font-size: 16px;
        margin: 20px 45px 0px;
    }
    @media (min-width: 550px) {
        font-size: 17px;
    }
    @media (min-width: 750px) {
        font-size: 18px;
    }
}
.projectDescription {
    font-family: $primaryMainFont;
    margin: 80px 35px 0px;
    line-height: 1.5;
    font-size: 14px;

    @media (min-width: 400px) {
        font-size: 15px;
        margin: 0px 45px 0px;
    }
    @media (min-width: 550px) {
        font-size: 16px;
    }
    @media (min-width: 750px) {
        font-size: 16px;
    }
}
.skillsUsedList {
    
    width: 190px;
    margin: 15px auto;
    text-align: left;
    // @media (min-width: 550px) {
    //     width: 500px;
    // }

}
.projectDescriptionWeb {
    font-family: $primaryMainFont;
    margin: 15px 35px 0px;
    line-height: 1.5;
    font-size: 14px;

    @media (min-width: 400px) {
        font-size: 15px;
        margin: 20px 45px 0px;
    }
    @media (min-width: 550px) {
        font-size: 16px;
    }
    @media (min-width: 750px) {
        font-size: 16px;
        margin: 20px 45px 30px;
    }
    @media (min-width: 1100px) {
        font-size: 16px;
        margin: 20px 150px 30px;
    }
}
.projectDescriptionWebWP {
    font-family: $primaryMainFont;
    margin: 15px 35px 0px;
    line-height: 1.5;
    font-size: 14px;

    @media (min-width: 400px) {
        font-size: 15px;
        margin: 20px 45px 0px;
    }
    @media (min-width: 550px) {
        font-size: 16px;
    }
    @media (min-width: 750px) {
        font-size: 16px;
        margin: 20px 65px 30px;
    }
    @media (min-width: 1100px) {
        font-size: 16px;
        margin: 20px auto 30px;
        width: 900px;
    }
}
.projectDescriptionOrdino {
    font-family: $primaryMainFont;
    margin: 15px 35px 0px;
    line-height: 1.5;
    font-size: 14px;

    @media (min-width: 400px) {
        font-size: 15px;
        margin: 20px 45px 0px;
    }
    @media (min-width: 550px) {
        font-size: 16px;
    }
    @media (min-width: 750px) {
        font-size: 16px;
        margin: 20px 45px 0px;
    }
    @media (min-width: 1100px) {
        font-size: 16px;
        margin: 20px 150px 0px;
    }
}
.projectDescriptiononSlideshow {
    font-family: $primaryMainFont;
    margin: 80px auto 0px;
    line-height: 1.5;
    font-size: 14px;
    max-width: 350px;
    // text-align: center;

    @media (min-width: 400px) {
        font-size: 15px;
    }
    @media (min-width: 550px) {
        font-size: 16px;
        max-width: 450px;
    }
    @media (min-width: 750px) {
        font-size: 16px;
        margin: 80px auto 0px;
        max-width: 700px;
    }
}
.herokuDisclaimer {
    font-family: $primaryMainFont;
    margin: 5px 35px 0px;
    font-size: 13px;
    font-style: oblique;
}
.GDesignShirt {
    width: 90vw;
    margin: auto;
    background-color: $darkgrey;
    text-align: center;
    justify-content: center;
}
.uxIndiv {
    text-align: center;
    justify-content: center;
    margin: auto;
}
.uxFullImage {
    width: 300px;
    height: 1800px;
    // background-color: $darkgrey;
    background-position: center top;
    background-size: contain; 
    align-self: center;

    @media (min-width: 650px) {
        width: 600px;
        height: 3600px;
    }
    @media (min-width: 1000px) {
        width: 1000px;
        height: 6000px;
    }
}
